var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.BusinessUnit}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("entities.businessUnits")))])]),_c('datatable',{ref:"businessUnits",attrs:{"fetch":_vm.$api.fetchBusinessUnits,"enableDownload":false,"objectName":"business_unit","identifier":"name"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.name'),"sortable":true}},[_vm._v(" "+_vm._s(item.name)+" ")])}},{key:"description",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.description'),"sortable":true}},[_vm._v(" "+_vm._s(item.description)+" ")])}},{key:"invoiceLocation",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.invoiceLocation'),"sortable":true}},[_vm._v(" "+_vm._s(item.invoiceLocation)+" ")])}},{key:"deliveryLocation",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.deliveryLocation'),"sortable":true}},[_vm._v(" "+_vm._s(item.deliveryLocation)+" ")])}},{key:"supplyChainOrganization",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.supplyChainOrganization'),"sortable":true}},[_vm._v(" "+_vm._s(item.supplyChainOrganization)+" ")])}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/businessunit/' + item.id}},[_c('i',{staticClass:"fa fa-search"})])],1)}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }