var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.OracleRgWarranties}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("entities.oracleRgWarranties")))])]),_c('datatable',{ref:"oracleRgWarranties",attrs:{"fetch":_vm.$api.fetchOracleRgWarranties,"enableDownload":false,"objectName":"oracle_rg_warranties","identifier":"warrantyId"},scopedSlots:_vm._u([{key:"orderName",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.name'),"sortable":false}},[_vm._v(" "+_vm._s(item.orderName)+" ")])}},{key:"name",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.total'),"sortable":false,"nofilter":true}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.amount))+" ")])}},{key:"status",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('oracle.rgWarranties.status'),"sortable":false}},[_vm._v(" "+_vm._s(_vm.$t(("oracleStatus." + (item.status))))+" ")])}},{key:"statusReason",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('oracle.rgWarranties.statusReason'),"sortable":false,"nofilter":true}},[_vm._v(" "+_vm._s(item.statusReason)+" ")])}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('div',{staticClass:"action-buttons"},[_c('button',{staticClass:"button is-success",on:{"click":function($event){return _vm.openSuccessModal(item.warrantyId)}}},[_vm._v(_vm._s(_vm.$t('oracle.rgWarranties.successModal')))]),_c('button',{staticClass:"button is-danger",on:{"click":function($event){return _vm.openErrorModal(item.warrantyId)}}},[_vm._v(_vm._s(_vm.$t('oracle.rgWarranties.reportError')))])])])}}])}),_c('modal',{ref:"errorModal",attrs:{"title":_vm.$t('oracle.rgWarranties.errorModal'),"action":_vm.$t('oracle.rgWarranties.report')},on:{"create":_vm.markRgWarrantyAsError}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('text-field',{attrs:{"label":_vm.$t('oracle.rgWarranties.reportError'),"inline":false,"edit":true,"required":""},model:{value:(_vm.errorText),callback:function ($$v) {_vm.errorText=$$v},expression:"errorText"}})],1)])]),_c('modal',{ref:"successModal",attrs:{"title":_vm.$t('oracle.rgWarranties.successModal'),"action":_vm.$t('oracle.rgWarranties.report')},on:{"create":_vm.markRgWarrantyAsSuccess}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('text-field',{attrs:{"label":_vm.$t('oracle.rgWarranties.invoiceName'),"inline":false,"edit":true,"required":""},model:{value:(_vm.errorText),callback:function ($$v) {_vm.errorText=$$v},expression:"errorText"}})],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }