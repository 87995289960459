<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Task">
        <h1 class="title">{{ $t("entities.tasks") }}</h1>
        <template slot="actions">
          <button
            class="button is-primary"
            :class="{ 'is-loading': refreshLoader }"
            @click="refresh"
          >
            {{ $t("common.refresh") }}
          </button>
        </template>
      </page-header>

      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>ID</th>
            <th>JobId</th>
            <th>Entity</th>
            <th>Entity ID</th>
            <th>Creator</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Events</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(task, index) in tasks" :key="index">
            <td>{{ task.id }}</td>
            <td>{{ task.jobId }}</td>
            <td>{{ task.objectName }}</td>
            <td>{{ task.objectId }}</td>
            <td>{{ task.creator.name }}</td>
            <td>
              <span
                class="tag"
                :class="{
                  'is-info': task.status === 'CREATED',
                  'is-success': task.status === 'SUCCESS',
                  'is-danger':
                    task.status === 'ERROR' || task.status === 'FAILED',
                  'is-warning': task.status === 'PROCESSING',
                }"
              >
                {{ task.status }}
              </span>
            </td>
            <td>{{ task.createdAt }}</td>
            <td>
              <div v-for="(event, index) in task.events" :key="index">
                <b>{{ event.time | datetime }}</b> {{ event.name }}
                <div
                  v-if="event.description"
                  style="text-wrap: wrap; word-break: break-word"
                >
                  {{ event.description }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Paginate
        :paginate="pagination"
        @change="paginate"
        :showOptions="false"
      />
    </div>
  </div>
</template>

<script>
import Paginate from "@/components/Paginate";
import PaginationBuilder from "@/lib/Paginate";
import axios from "axios";
export default {
  name: "TaskList",
  components: {
    Paginate,
  },
  data() {
    return {
      tasks: [],
      refreshLoader: false,
      pagination: {
        page: 1,
        total: 0,
        size: 20,
        sort: {
          column: "id",
          direction: "DESC",
        },
      },
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    preparePaginationObject() {
      return {
        ...this.pagination,
        page: this.pagination.page - 1,
        sort: {
          ...this.pagination.sort,
        },
        filters: [],
      };
    },
    paginate(p) {
      this.pagination = p;
      this.refresh();
    },
    refresh() {
      this.refreshLoader = true;
      const p = new PaginationBuilder(this.preparePaginationObject());
      axios
        .get(`/tasks?${p}`)
        .then((response) => {
          this.tasks = response.data.content;
          this.pagination.total = response.data.totalElements;
        })
        .finally(() => {
          this.refreshLoader = false;
        });
    },
  },
};
</script>
