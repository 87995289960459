<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.BusinessUnit"
        :edit="edit"
        :save="save"
        :cancel="cancel"
      >
        <h1 class="title">
          <breadcrumb
            location="/businessunits"
            :text="$t('entities.businessUnit')"
          ></breadcrumb>
          {{ businessunit.name }}
        </h1>
      </page-header>
      <div class="panel">
        <div class="panel-heading has-background-primary">
          <span>{{ $t("common.information") }}</span>
        </div>
        <div class="panel-block is-block no-border">
          <div class="columns" v-if="businessunit.id">
            <div class="column is-half" v-if="businessunit">
              <text-field
                :label="$t('common.name')"
                v-model="businessunit.name"
                required
              ></text-field>
              <text-field
                :label="$t('common.description')"
                v-model="businessunit.description"
                required
              ></text-field>
              <text-field
                :label="$t('common.invoiceLocation')"
                v-model="businessunit.invoiceLocation"
                required
              ></text-field>
              <text-field
                :label="$t('common.deliveryLocation')"
                v-model="businessunit.deliveryLocation"
                required
              ></text-field>
              <text-field
                :label="$t('common.supplyChainOrganization')"
                v-model="businessunit.supplyChainOrganization"
                required
              ></text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "BusinessUnit",
  props: ["id"],
  data() {
    return {
      businessunit: {
        name: null,
        description: null,
        invoiceLocation: null,
        deliveryLocation: null,
        supplyChainOrganization: null,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
      user: "auth/getUser",
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      return axios.get(`/businessunit/${this.id}`).then((r) => {
        this.businessunit = r.data;
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios
            .delete(`/businessunit/${this.id}`, this.businessunit)
            .then((response) => {
              if (response.data.success) {
                this.$router.replace("/businessunits");
              }
            })
      );
    },
    save() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          return axios
            .put(`/businessunit/${this.id}`, this.businessunit)
            .then((response) => {
              if (response.data) {
                this.businessunit = response.data;
                this.cancel();
              }
            });
        }
        return Promise.resolve();
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData().then(() => {
        this.$store.dispatch("states/setEdition", false);
      });
    },
  },
};
</script>
