<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.PaymentTerm"
        :edit="edit"
        :save="save"
        :cancel="cancel"
      >
        <h1 class="title">
          <breadcrumb
            location="/paymentterms"
            :text="$t('entities.paymentTerm')"
          ></breadcrumb>
          {{ paymentterm.name }}
        </h1>
      </page-header>
      <div class="panel">
        <div class="panel-heading has-background-primary">
          <span>{{ $t("common.information") }}</span>
        </div>
        <div class="panel-block is-block no-border">
          <div class="columns" v-if="paymentterm.id">
            <div class="column is-half" v-if="paymentterm">
              <field readonly :label="$t('common.name')">
                {{ paymentterm.name }}
              </field>
              <text-field
                :label="$t('common.oracleCode')"
                v-model="paymentterm.oracleCode"
                required
              ></text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "PaymentTerm",
  props: ["id"],
  data() {
    return {
      paymentterm: {
        name: null,
        oracleCode: null,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
      user: "auth/getUser",
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      return axios.get(`/payment_term/${this.id}`).then((r) => {
        this.paymentterm = r.data;
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios
            .delete(`/payment_term/${this.id}`, this.paymentterm)
            .then((response) => {
              if (response.data.success) {
                this.$router.replace("/paymentterms");
              }
            })
      );
    },
    save() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          return axios
            .put(`/payment_term/${this.id}`, this.paymentterm)
            .then((response) => {
              if (response.data) {
                this.paymentterm = response.data;
                this.cancel();
              }
            });
        }
        return Promise.resolve();
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData().then(() => {
        this.$store.dispatch("states/setEdition", false);
      });
    },
  },
};
</script>
