<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.BusinessUnit">
        <h1 class="title">{{ $t("entities.businessUnits") }}</h1>
      </page-header>

      <datatable
        ref="businessUnits"
        :fetch="$api.fetchBusinessUnits"
        :enableDownload="false"
        objectName="business_unit"
        identifier="name"
      >
        <div
          slot="name"
          slot-scope="{ item }"
          :title="$t('common.name')"
          :sortable="true"
        >
          {{ item.name }}
        </div>
        <div
          slot="description"
          slot-scope="{ item }"
          :title="$t('common.description')"
          :sortable="true"
        >
          {{ item.description }}
        </div>
        <div
          slot="invoiceLocation"
          slot-scope="{ item }"
          :title="$t('common.invoiceLocation')"
          :sortable="true"
        >
          {{ item.invoiceLocation }}
        </div>
        <div
          slot="deliveryLocation"
          slot-scope="{ item }"
          :title="$t('common.deliveryLocation')"
          :sortable="true"
        >
          {{ item.deliveryLocation }}
        </div>
        <div
          slot="supplyChainOrganization"
          slot-scope="{ item }"
          :title="$t('common.supplyChainOrganization')"
          :sortable="true"
        >
          {{ item.supplyChainOrganization }}
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link
            :to="'/businessunit/' + item.id"
            class="button is-primary"
          >
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessUnitList",
};
</script>
