<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.OracleRgWarranties">
        <h1 class="title">{{ $t("entities.oracleRgWarranties") }}</h1>
      </page-header>

      <datatable
        ref="oracleRgWarranties"
        :fetch="$api.fetchOracleRgWarranties"
        :enableDownload="false"
        objectName="oracle_rg_warranties"
        identifier="warrantyId"
      >
        <div
          slot="orderName"
          slot-scope="{ item }"
          :title="$t('common.name')"
          :sortable="false"
        >
          {{ item.orderName }}
        </div>
        <div
          slot="name"
          slot-scope="{ item }"
          :title="$t('common.total')"
          :sortable="false"
          :nofilter="true"
        >
          {{ item.amount | priceEUR }}
        </div>

        <div
          slot="status"
          slot-scope="{ item }"
          :title="$t('oracle.rgWarranties.status')"
          :sortable="false"
        >
          {{ $t(`oracleStatus.${item.status}`) }}
        </div>

        <div
          slot="statusReason"
          slot-scope="{ item }"
          :title="$t('oracle.rgWarranties.statusReason')"
          :sortable="false"
          :nofilter="true"
        >
          {{ item.statusReason }}
        </div>

        <div slot="actions" slot-scope="{ item }">
          <div class="action-buttons">
            <button class="button is-success" @click="openSuccessModal(item.warrantyId)">{{ $t('oracle.rgWarranties.successModal') }}</button>
            <button class="button is-danger" @click="openErrorModal(item.warrantyId)">{{ $t('oracle.rgWarranties.reportError') }}</button>
          </div>
        </div>
      </datatable>

      <modal ref="errorModal"
             :title="$t('oracle.rgWarranties.errorModal')"
             :action="$t('oracle.rgWarranties.report')"
             @create="markRgWarrantyAsError">
        <div class="columns">
          <div class="column is-half">
            <text-field :label="$t('oracle.rgWarranties.reportError')"
                        v-model="errorText"
                        :inline="false"
                        :edit="true"
                        required />
          </div>
        </div>
      </modal>

      <modal ref="successModal"
             :title="$t('oracle.rgWarranties.successModal')"
             :action="$t('oracle.rgWarranties.report')"
             @create="markRgWarrantyAsSuccess">
        <div class="columns">
          <div class="column is-half">
            <text-field :label="$t('oracle.rgWarranties.invoiceName')"
                        v-model="errorText"
                        :inline="false"
                        :edit="true"
                        required />
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "OracleConfigurationList",
  data() {
    return {
      errorText: "",
      currentSelectedRgWarranty: null,
    }
  },
  methods: {
    openErrorModal (currentRgWarranty) {
      this.currentSelectedRgWarranty = currentRgWarranty;
      this.$refs.errorModal.open();
    },
    openSuccessModal (currentRgWarranty) {
      this.currentSelectedRgWarranty = currentRgWarranty;
      this.$refs.successModal.open();
    },
    markRgWarrantyAsError()
    {
      axios.post(`/oracle/rg-warranties/${this.currentSelectedRgWarranty}/error`, {
        statusReason: this.errorText
      }).then((response) => {
        if (response.data.success) {
          this.currentSelectedRgWarranty = null;
          this.errorText = "";
          this.$refs.oracleRgWarranties.refresh();
          this.$refs.errorModal.close();
        }
      });
    },
    markRgWarrantyAsSuccess()
    {
      axios.post(`/oracle/rg-warranties/${this.currentSelectedRgWarranty}/success`, {
        statusReason: this.errorText
      }).then((response) => {
        if (response.data.success) {
          this.currentSelectedRgWarranty = null;
          this.errorText = "";
          this.$refs.oracleRgWarranties.refresh();
          this.$refs.successModal.close();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.action-buttons {
  display: flex;

  .is-danger {
    margin-left: 10px;
  }
}
</style>
