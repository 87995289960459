<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.OracleConfiguration"
        :edit="edit"
        :save="save"
        :cancel="cancel"
      >
        <h1 class="title">
          <breadcrumb
            location="/oracleconfigurations"
            :text="$t('entities.oracleConfiguration')"
          ></breadcrumb>
          {{ oracleconfiguration.name }}
        </h1>
      </page-header>

      <div class="notification is-danger is-light">
        Attention, le changement de valeur de configuration Oracle a un impact
        direct sur l'envoi des commandes et receptions dans Oracle.
      </div>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <span>{{ $t("common.information") }}</span>
        </div>
        <div class="panel-block is-block no-border">
          <div class="columns" v-if="oracleconfiguration.id">
            <div class="column is-half" v-if="oracleconfiguration">
              <field readonly :label="$t('common.code')">
                {{ oracleconfiguration.code }}
              </field>
              <field readonly :label="$t('common.name')">
                {{ oracleconfiguration.name }}
              </field>
              <field readonly :label="$t('common.description')">
                {{ oracleconfiguration.description }}
              </field>
              <text-field
                :label="$t('common.value')"
                v-model.trim="oracleconfiguration.value"
                required
              ></text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

    <script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "OracleConfiguration",
  props: ["id"],
  data() {
    return {
      oracleconfiguration: {
        name: null,
        oracleCode: null,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
      user: "auth/getUser",
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      return axios.get(`/oracle/configuration/${this.id}`).then((r) => {
        this.oracleconfiguration = r.data;
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios
            .delete(
              `/oracle/configuration/${this.id}`,
              this.oracleconfiguration
            )
            .then((response) => {
              if (response.data.success) {
                this.$router.replace("/oracleconfigurations");
              }
            })
      );
    },
    save() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          return axios
            .put(`/oracle/configuration/${this.id}`, this.oracleconfiguration)
            .then((response) => {
              if (response.data) {
                this.oracleconfiguration = response.data;
                this.cancel();
              }
            });
        }
        return Promise.resolve();
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData().then(() => {
        this.$store.dispatch("states/setEdition", false);
      });
    },
  },
};
</script>
