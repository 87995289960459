<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.PaymentTerm">
        <h1 class="title">{{ $t("entities.paymentTerms") }}</h1>
      </page-header>

      <datatable
        ref="paymentTerms"
        :fetch="$api.fetchPaymentTerms"
        :enableDownload="false"
        objectName="payment_term"
        identifier="name"
      >
        <div
          slot="name"
          slot-scope="{ item }"
          :title="$t('common.name')"
          :sortable="true"
        >
          {{ item.name }}
        </div>
        <div
          slot="oracleCode"
          slot-scope="{ item }"
          :title="$t('common.oracleCode')"
          :sortable="true"
        >
          {{ item.oracleCode }}
        </div>
        <div slot="actions" slot-scope="{ item }">
          <router-link
            :to="'/paymentterm/' + item.id"
            class="button is-primary"
          >
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>
  </div>
</template>

  <script>
export default {
  name: "PaymentTermList",
};
</script>
