var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.OracleConfiguration}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("entities.oracleConfiguration")))])]),_c('div',{staticClass:"notification is-danger is-light"},[_vm._v(" Attention, le changement de valeur de configuration Oracle a un impact direct sur l'envoi des commandes et receptions dans Oracle. ")]),_c('datatable',{ref:"oracleConfigurations",attrs:{"fetch":_vm.$api.fetchOracleConfigurations,"enableDownload":false,"objectName":"oracle_configuration","identifier":"code"},scopedSlots:_vm._u([{key:"code",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.code'),"sortable":true}},[_vm._v(" "+_vm._s(item.code)+" ")])}},{key:"name",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.name'),"sortable":true}},[_vm._v(" "+_vm._s(item.name)+" ")])}},{key:"description",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.description'),"sortable":true}},[_vm._v(" "+_vm._s(item.description)+" ")])}},{key:"value",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.value'),"sortable":true}},[_vm._v(" "+_vm._s(item.value)+" ")])}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/oracleconfiguration/' + item.id}},[_c('i',{staticClass:"fa fa-search"})])],1)}}])}),_c('div',{staticClass:"mt-5"},[_c('button',{staticClass:"button is-small is-default",on:{"click":function($event){return _vm.$router.push('/tasks')}}},[_vm._v(" Tasks ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }